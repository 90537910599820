import {createRouter,createWebHistory} from "vue-router";
import Releases from "../vue/chapters/Releases.vue";
import Pending from "../vue/chapters/Pending.vue";
import Settings from "../vue/chapters/Settings.vue";
import UploadRelease from "../vue/chapters/UploadRelease.vue";
import Statistics from "../vue/chapters/Statistics.vue";
import Karaoke from "../vue/chapters/Karaoke.vue";
import Users from "../vue/chapters/Users.vue";

export default createRouter (
	{
		history:createWebHistory (),
		routes:
			[
				{path:'/',component:Releases,name:'Все релизы',meta:{user:'ADMIN'}},
				{path:'/',component:Releases,name:'Мои релизы',meta:{user:'ARTIST'}},
				{path:'/pending',component:Pending,name:'На проверке',meta:{user:'ADMIN'}},
				{path:'/users',component:Users,name:'Пользователи',meta:{user:'ADMIN'}},
				{path:'/upload_release',component:UploadRelease,name:'Загрузить релиз',meta:{user:'ARTIST'}},
				{path:'/statistics',component:Statistics,name:'Статистика',meta:{user:'ARTIST'}},
				{path:'/karaoke',component:Karaoke,name:'Караоке текст',meta:{user:'ARTIST'}},
				{path:'/settings',component:Settings,name:'Настройки'},
			]
	});
