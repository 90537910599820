<script setup lang="ts">
import {inject,ref,provide} from "vue";
import {isPlainObject} from "lodash";
import AxiosWrapper from "../lib/AxiosWrapper/classes/AxiosWrapper";
import AppMain from "./app/AppMain.vue";
import AppLogin from "./app/AppLogin.vue";

const user=ref (undefined);
provide ('user',user);
const axios:AxiosWrapper=inject ('axios');
axios.unauthorized (unauthorized).success (success).get ('user').run ();

function unauthorized ():void
	{
		user.value=null;
	}

function success (data:any,{request}):void
	{
		let {responseURL}=request;
		if (['/user','/login'].includes (responseURL.replace (axios.defaults.baseURL,'')))
			{
				user.value=isPlainObject (data)?data:null;
			}
	}
</script>
<template>
	<app-main v-if="user"/>
	<app-login v-else-if="user===null"/>
</template>
