<script setup lang="ts">
defineProps (
	{
		disabled:Boolean,
		readonly:Boolean,
	});
const model=defineModel ();
const error=defineModel ('error');
</script>
<template>
	<label class="ui-form-checkbox">
		<input v-model="model" v-bind="{disabled,readonly}" @focus="error=undefined" type="checkbox" class="input">
		<span v-if="$slots.default" class="label">
			<slot/>
		</span>
		<span v-if="error" v-text="error" class="error"/>
	</label>
</template>
