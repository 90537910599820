import {createApp} from "vue";
import router from "./router";
import axios from "./lib/AxiosWrapper";
import App from "./vue/App.vue";
import UiFormInput from "./vue/components/ui/form/UiFormInput.vue";
import UiFormCheckbox from "./vue/components/ui/form/UiFormCheckbox.vue";

axios.defaults.headers.common['X-Requested-With']='XMLHttpRequest';
axios.defaults.baseURL=`${window.location.origin}/xhr`;

const instance=createApp (App);
instance.use (router);
instance.provide ('axios',axios);
instance.component ('ui-form-input',UiFormInput);
instance.component ('ui-form-checkbox',UiFormCheckbox);
instance.mount ('body');
