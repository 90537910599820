<script setup lang="ts">
import {computed} from "vue";

const props=defineProps (
	{
		type:String,
		disabled:Boolean,
		readonly:Boolean,
	});
const model=defineModel ();
const error=defineModel ('error');
const inputType=computed (()=>
	{
		let type=props.type.toLowerCase ();
		switch (type)
			{
				case 'date':
				case 'datetime-local':
				case 'email':
				case 'number':
				case 'password':
				case 'text':
				case 'time':
					return type;
				default:
					return 'text';
			}
	});
</script>
<template>
	<label class="ui-form-input">
		<span v-if="$slots.default" class="label">
			<slot/>
		</span>
		<input v-model="model" v-bind="{disabled,readonly}" :type="inputType" @focus="error=undefined" class="input">
		<span v-if="error" v-text="error" class="error"/>
	</label>
</template>
