<script setup lang="ts">
import {useRouter} from "vue-router";
import NavItem from "../ui/nav/NavItem.vue";
import {inject} from "vue";

const router=useRouter ();
const user=inject ('user');
const routes=router.getRoutes ().filter (route=>!route.meta.user || route.meta.user===user.value.role);
</script>
<template>
	<nav-item v-for="route in routes" v-bind:route/>
</template>
