<script setup lang="ts">
import {inject,ref,onMounted,onUnmounted,getCurrentInstance} from "vue";
import {mapValues} from "lodash";
import AxiosWrapper from "../../lib/AxiosWrapper/classes/AxiosWrapper";

const formData=ref ({});
const errors=ref ({});
const axios:AxiosWrapper=inject ('axios');

onMounted (mounted);
onUnmounted (unmounted);

function submit ():void
	{
		axios.post ('login',formData.value).unprocessableEntity (unprocessableEntity).run ()
	}

function unprocessableEntity (response:any):void
	{
		errors.value=mapValues (response.errors,'0');
	}

function mounted ():void
	{
		document.body.id='login';
	}

function unmounted ():void
	{
		document.body.removeAttribute ('id');
	}
</script>
<template>
	<form @submit.prevent.stop="submit">
		<ui-form-input v-model="formData['email']" v-model:error="errors['email']" type="email">
			Email
		</ui-form-input>
		<ui-form-input v-model="formData['password']" v-model:error="errors['password']" type="password">
			Пароль
		</ui-form-input>
		<ui-form-checkbox v-model="formData['remember']">Запомнить</ui-form-checkbox>
		<button type="submit">Войти</button>
	</form>
</template>
