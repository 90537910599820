<script setup lang="ts">
</script>
<template>
	<header>
		<slot name="header"/>
	</header>
	<nav>
		<slot name="nav"/>
	</nav>
	<main>
		<slot/>
	</main>
</template>
